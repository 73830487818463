nav.main-page-nav {
  display: flex;
  margin-top: 30px;
  height: 40px;

  .anyfiddle-logo {
    margin-left: 25px;
    height: 32px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      margin-right: 60px;
      display: flex;
      a {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 14px;
        line-height: 32px;
      }

      .sign-in-button,
      .my-projects-button {
        border: 1px solid #444;
        border-radius: 5px;
        background-color: #262626;
        padding: 12px 30px;

        &:hover {
          background-color: #333;
        }
      }
    }
  }
  .sign-in-action {
    height: auto;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #262626;

    .login-button,
    .user-button {
      padding: 3px 10px;
      &:hover {
        background-color: transparent;
      }
      svg {
        margin-right: 8px;
      }
      .user-avatar {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  nav.main-page-nav {
    margin-top: 20px;

    .anyfiddle-logo {
      margin-left: 10px;
    }
    ul {
      display: none;
    }
  }
}
