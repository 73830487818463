.auth-loader {
  width: 100;
  height: 100vh;
  display: flex;
}

.page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page-header {
    position: sticky;
    top: 0;
    background-color: #1d1d1d;
    z-index: 1;
    border-right: 1px solid #333;
    border-left: 1px solid #333;
  }

  .page-container {
    flex: 1;
    border-right: 1px solid #333;
    border-left: 1px solid #333;
  }
}

.no-br {
  white-space: nowrap;
}
.grow {
  flex-grow: 1;
}

::placeholder {
  color: #666;
}

button {
  font-family: inherit;
}

::selection {
  color: #eee;
  background: #398539;
}

.button:not(.monaco-editor .button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #444;
  color: #eee;
  border-left: 1px solid #444;
  font-family: inherit;
  outline: none;
  padding: 12px 15px;
  font-size: 12px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #494949;
  }

  &:active {
    background-color: #555;
  }

  &:disabled {
    color: #666;
    background-color: #393939;
  }
}

.button-green,
.button-red {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  background-color: #008900;
  border-radius: 5px;
  color: #fff;
  outline: none;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  text-shadow: 1px 1px 1px #000;
  &:hover {
    background-color: #29a229;
  }
}

.button-red {
  background-color: #b00000;
  &:hover {
    background-color: #c00;
  }
}

.link {
  color: #29a229;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.tip {
  font-size: 13px;
  padding: 10px;
  box-sizing: border-box;
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  code {
    border: 1px solid #505050;
    background-color: #444;
    margin: 4px 0;
    padding: 3px;
    border-radius: 3px;
  }
}

.fixed-width-container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.tippy-box {
  background-color: #262626;
  border: 1px solid #2c2c2c;
  box-shadow: 3px 3px 20px #111;
  color: #ccc;
  outline: none;

  .tippy-content {
    padding: 10px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 13px;
      li {
        padding: 0 15px;
        height: 40px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #333;
          color: #eee;
        }

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.tippy-box[data-theme='menu'] {
  background-color: #262626;
  border: 1px solid #333;
  box-shadow: 0 0 10px #111;
  color: #ccc;

  .tippy-content {
    padding: 0;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 13px;
      li {
        padding: 0 15px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #333;
          color: #eee;
        }

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .fixed-width-container {
    border: none;
  }
}
