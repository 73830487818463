.sign-in-action {
  height: 100%;
  display: flex;
  align-items: center;

  .login-button,
  .user-button {
    display: flex;
    align-items: center;
    height: 100%;
    color: #eee;
    background-color: transparent;
    border: none;
    padding: 0 10px;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #333;
      color: #fff;
    }

    svg {
      margin-right: 8px;
    }

    .user-avatar {
      width: 32px;
      height: 32px;
      background-size: contain;
      border-radius: 50%;
    }

    .user-username {
      margin-left: 10px;
      color: #aaa;
      font-size: 12px;
    }
  }
}
.signing-out-overlay {
  .loader {
    font-size: 24px;
  }
}
