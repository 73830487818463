.user-menu {
  width: 200px;

  .user-details {
    padding: 20px;

    .user-name {
      margin-bottom: 10px;
      font-weight: 600;
    }

    .user-username {
      color: #aaa;
    }
  }

  .user-menu-section {
    border-bottom: 1px solid #333;

    &:last-child {
      border-bottom: none;
    }
  }

  .user-menu-item {
    padding: 12px 20px;
    background-color: transparent;
    color: #ccc;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    cursor: pointer;
    display: block;
    box-sizing: border-box;

    &:hover {
      background-color: #333;
    }

    svg {
      margin-right: 7px;
      color: #aaa;
    }
  }
}
