.footer {
  padding: 40px;
  color: #999;
  background-color: #222;

  .footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .footer-column {
      display: flex;
      flex-direction: column;
      font-size: 13px;

      .footer-section {
        margin-bottom: 20px;
        h4 {
          font-size: inherit;
          color: #aaa;
          margin: 0;
          margin-bottom: 10px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            a {
              display: flex;
              align-items: center;
              color: #ccc;
              padding: 5px 0;

              .footer-social-logo {
                margin-right: 8px;
                font-size: 14px;
              }
            }
          }
        }
      }

      &.logo-column {
        display: flex;
        flex-direction: column;

        img {
          width: 48px;
          height: 48px;
          margin-bottom: 15px;
        }

        .anyfiddle-logo {
          height: 32px;
        }
      }
    }
  }
  .copyright-column {
    padding-top: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  .footer {
    .footer-row {
      flex-direction: column;

      .footer-column {
        .footer-section {
          margin-bottom: 30px;
          h4 {
            font-size: 16px;
          }
        }
        &.logo-column {
          text-align: center;
          align-items: center;
          padding-bottom: 40px;
        }
      }
    }
  }
}
