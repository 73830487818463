.anyfiddle-logo {
  margin-top: 5px;
  width: auto;
  max-width: 100%;
  height: 50px;
  text-align: left;

  .anyfiddle-text {
    fill: #fff;
  }
}
.pro-badge {
  .pro-rect {
    fill: #008900;
  }
  .pro-text {
    fill: #fff;
  }
}
