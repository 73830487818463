.toast {
  position: fixed;
  bottom: 30px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background-color: #333;
  border: 1px solid #333;
  box-shadow: 3px 3px 20px #111;
  color: #eee;
  font-size: 13px;
  z-index: 10;

  svg {
    margin-right: 15px;
    font-size: 16px;
  }

  &.success {
    svg {
      color: #29a229;
    }
  }

  &.error {
    svg {
      color: #d00;
    }
  }

  &.warn {
    svg {
      color: #ffc107;
    }
  }
}
