.home-page {
  flex: 1;
  overflow-y: auto;
  font-size: 14px;
  color: #eee;

  .clearfix:after {
    content: ' '; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  .acquihire-notice {
    margin-bottom: 60px;
    border-bottom: 1px solid #333;
    line-height: 2;
    font-size: 16px;
    background-color: #222;
    h2 {
      margin-bottom: 20px;
    }
    .fixed-width-container {
      padding: 60px;
      font-size: 14px;
    }
  }

  .txt-mobile-error {
    padding: 20px;
    background-color: #600;
    color: #fff;
    text-align: center;
    display: none;
  }

  .start-button {
    padding: 15px 40px;
    margin-bottom: 50px;
    font-size: 18px;
    border-radius: 5px;
  }

  .top-section {
    text-align: center;
    overflow: hidden;
    padding-bottom: 60px;
    border-bottom: 1px solid #333;

    h1 {
      text-align: center;
      margin-top: 60px;
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: 100;
    }

    p {
      margin: 0 auto;
      margin-bottom: 40px;
      font-size: 18px;
      color: #eee;
    }

    .demo-container {
      margin: 0 auto;
      border-radius: 10px;
      display: block;
      box-shadow: 0 0 40px #111;
      overflow: hidden;
      border: 1px solid #262626;
      position: relative;
      width: 900px;

      .demo-buttons {
        display: flex;
        background-color: #222;
        padding: 12px 20px;
        padding-bottom: 15px;
        & > div {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          margin-right: 10px;
        }
        .demo-button-1 {
          background-color: #fd3d3e;
        }
        .demo-button-2 {
          background-color: #ffe202;
        }
        .demo-button-3 {
          background-color: #3efc90;
        }
      }

      .play-button {
        position: absolute;
        left: calc(50% - 36px);
        top: calc(50% - 36px);
        font-size: 72px;
        line-height: 72px;
        opacity: 0.8;
        color: #ccc;
        cursor: pointer;
        z-index: 2;
      }
      video {
        width: 100%;
        height: auto;
      }
      video::-internal-media-controls-overlay-cast-button {
        display: none;
      }
    }
  }

  h2 {
    text-align: center;
    font-weight: 100;
    margin: 0;
    font-size: 28px;
    margin-bottom: 60px;
  }

  h3 {
    font-weight: 100;
    font-size: 24px;
    margin: 0;
    margin-bottom: 20px;
  }
  section {
    padding: 70px 0;
    border-bottom: 1px solid #333;
  }

  .features-section {
    .feature-item {
      margin-bottom: 120px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      .feature-content {
        flex: 3;

        p {
          color: #ccc;
          line-height: 1.7;
          font-size: 15px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          padding-top: 15px;
          li {
            display: flex;
            margin-bottom: 15px;
            svg {
              margin-right: 15px;
              color: #666;
              font-size: 20px;
              margin-top: 3px;
            }
          }
        }
      }

      .feature-image {
        flex: 4;
        text-align: right;
      }

      &:nth-child(2n + 1) {
        flex-direction: row-reverse;
        .feature-image {
          text-align: left;
        }
      }
    }
  }

  .pricing-section {
    color: #fff;

    .pricing-items-container {
      display: flex;

      .pricing-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 80px;
        box-shadow: 0 0 30px #161616;
        padding: 35px;
        box-sizing: border-box;
        border-radius: 10px;
        color: #ccc;
        border: 1px solid #262626;
        background-color: #222;

        &:last-child {
          margin-right: 0;
        }

        h3 {
          color: #eee;
          font-size: 24px;
          .pricing-coming-soon {
            margin-left: 15px;
            font-size: 14px;
          }
        }
        .price-box {
          margin-bottom: 20px;
          font-size: 20px;
        }

        .pricing-per-month-text {
          margin-left: 5px;
          font-size: 14px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          flex-grow: 1;
          li {
            margin-bottom: 15px;
            display: flex;

            svg {
              margin-right: 15px;
              margin-top: 4px;
              color: #29a229;
            }
          }
        }

        .button-green {
          margin: 0;
          margin-top: 45px;
          width: 100%;
          font-size: 16px;
          padding: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .fixed-width-container {
      padding: 0 30px;
    }
    .top-section {
      h1 {
        font-size: 22px;
      }
      .demo-container {
        max-width: 100%;

        .demo-buttons {
          padding: 7px;

          & > div {
            width: 10px;
            height: 10px;
          }
        }

        .demo-video-container {
          video {
            margin-top: -15px !important;
          }
        }
      }
    }
    .features-section {
      .feature-item {
        display: block;
        .feature-content {
          width: 100%;
          margin-bottom: 15px;
        }

        .feature-image {
          width: 100%;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .pricing-section {
      .pricing-items-container {
        flex-direction: column;
        .pricing-item {
          margin: 0;
          margin-bottom: 30px;
          width: 100%;
          height: auto;
          padding: 25px;
        }
      }
    }

    .txt-mobile-error {
      display: block;
    }
  }
}
