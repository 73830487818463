.overlay-portal {
  .overlay-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #000c;
    backdrop-filter: blur(3px);
    z-index: 10000;
    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      background-color: #1d1d1d;
      border: 1px solid #222;
      box-shadow: 0 0 20px #000;
      color: #ccc;
      outline: none;
      max-width: 600px;
      width: calc(100% - 30px);
      padding: 30px;
      box-sizing: border-box;
    }
  }
}
