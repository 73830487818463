.app-bar {
  height: 60px;
  background-color: #1d1d1d;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333;

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    height: 100%;
    color: #eee;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #222;
      color: #fff;
    }
  }

  .anyfiddle-logo {
    margin-left: 25px;
    height: 20px;
  }

  .grow {
    flex-grow: 1;
  }

  .app-bar-actions {
    height: 100%;
    display: flex;
    .app-bar-action {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      background-color: transparent;
      border: none;
      outline: none;
      color: #ccc;
      text-decoration: none;
      font-weight: 600;

      svg {
        margin-right: 6px;
      }

      &:hover {
        background-color: #333;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .app-bar {
    .app-bar-actions {
      .app-bar-action {
        svg {
          margin: 0;
        }
        .app-bar-action-text {
          display: none;
        }
      }
    }
  }
}
